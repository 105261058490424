import { Atom, atom, useAtom } from 'jotai';

import { JotaiAtom } from 'src/types/types';

export enum DialogId {
  'JoinGame' = 'JoinGame',
  'JoinGameUserSetup' = 'JoinGameUserSetup',
}

const dialogAtoms: Record<DialogId, JotaiAtom<boolean>> = {
  JoinGame: atom(false),
  JoinGameUserSetup: atom(false),
};

export const useDialog = (dialogId: DialogId) => {
  const [dialog, setDialog] = useAtom(dialogAtoms[dialogId]);

  const openDialog = () => setDialog(true);

  const closeDialog = () => setDialog(false);

  return { open: !!dialog, openDialog, closeDialog };
};
