import { createTheme } from '@mui/material/styles';

import { colors } from './design-system.colors';
import { BREAKPOINTS } from './design-system.constants';

const font = "'Chivo', sans-serif";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: BREAKPOINTS.xs,
      sm: BREAKPOINTS.sm,
      md: BREAKPOINTS.md,
      lg: BREAKPOINTS.lg,
      xl: BREAKPOINTS.xl,
    },
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 14,
    fontFamily: font,
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.primary[500],
    },
    secondary: {
      main: '#19857b',
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    error: {
      main: colors.error,
    },
    info: {
      main: colors.info,
    },
    background: {
      default: '#fff',
      paper: '#e8f8fa',
    },
    text: {
      primary: colors.text,
      disabled: colors.dark[300],
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circleIndeterminate: {
          animation: 'MuiCircularProgress-keyframes-circular-dash .8s ease-in-out infinite',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'initial',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 600,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          minHeight: 32,
        },
        contained: {
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          border: `2px solid ${colors.primary[500]}`,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          backgroundColor: `${colors.primary[500]} !important`,
          color: '#ffffff',

          '&:hover': {
            backgroundColor: `${colors.primary[500]} !important`,
          },
          '&:focus': {
            backgroundColor: `${colors.primary[500]} !important`,
          },
          '&:active': {
            backgroundColor: `${colors.primary[500]} !important`,
          },
          '& .Loading-spinner': {
            color: '#ffffff',
          },
        },
        outlinedPrimary: {
          borderWidth: 2,
          borderColor: colors.primary[500],
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,

          '&:hover': {
            borderWidth: 2,
            borderColor: colors.primary[400],
          },
          '&:focus': {
            borderWidth: 2,
            borderColor: colors.primary[400],
          },
          '&:active': {
            borderWidth: 2,
            borderColor: colors.primary[400],
          },
        },
        outlinedSecondary: {
          borderWidth: 2,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
        },
        sizeLarge: {
          height: '55px',
          fontSize: '16px',
          fontWeight: 700,
          '@media screen and (min-width: 400px)': {
            fontSize: '18px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 18,
          padding: '12px !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        nativeInput: {
          height: 18,
          padding: '12px !important',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: colors.light,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '14px',
          color: colors.primary[500],
        },
        shrink: {
          fontWeight: 500,
          color: colors.primary[500],
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.dark[700],
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          padding: '16px',
          overflowY: 'auto',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          transition: 'none !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.dark[400],
          height: '55px',

          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: colors.primary[400],
            },
          },
        },
        notchedOutline: {
          borderColor: 'transparent',
        },
        input: {
          '&:-webkit-autofill': {
            borderRadius: 0,
            '-webkit-box-shadow': '0 0 0 100px #3f4d6a inset',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: colors.primary[500],
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: colors.primary[300],
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
});
