import { DialogId, useDialog } from 'src/hooks/useDialog';
import { useLoginDialog } from 'src/hooks/useLoginDialog';

export const ModalsContainer = () => {
  const { loginDialog } = useLoginDialog();
  const joinGameDialog = useDialog(DialogId.JoinGame);
  const joinGameUserSetupDialog = useDialog(DialogId.JoinGameUserSetup);

  return <></>;
};
