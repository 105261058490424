export const colors = {
  primary: {
    // '50': '#f0edf3',
    // '100': '#d9d1e0',
    // '200': '#c0b3cb',
    // '300': '#a795b6',
    // '400': '#947ea7',
    // '500': '#816797',
    // '600': '#795f8f',
    // '700': '#6e5484',
    // '800': '#644a7a',
    // '900': '#513969',

    // '50': '#fcf3ea',
    // '100': '#f8e2ca',
    // '200': '#f3cfa7',
    // '300': '#eebb84',
    // '400': '#ebad69',
    // '500': '#e79e4f',
    // '600': '#e49648',
    // '700': '#e08c3f',
    // '800': '#dd8236',
    // '900': '#d77026',

    // '50': '#ffecec',
    // '100': '#ffd1d1',
    // '200': '#ffb2b2',
    // '300': '#ff9393',
    // '400': '#ff7b7b',
    // '500': '#ff6464',
    // '600': '#ff5c5c',
    // '700': '#ff5252',
    // '800': '#ff4848',
    // '900': '#ff3636',

    // '50': '#fceded', // brighter
    // '100': '#f8d1d1',
    // '200': '#f3b2b2',
    // '300': '#ee9393',
    // '400': '#eb7c7c',
    // '500': '#e76565',
    // '600': '#e45d5d',
    // '700': '#e05353',
    // '800': '#dd4949',
    // '900': '#d73737',

    // '50': '#faeeee', // darker
    // '100': '#f4d4d4',
    // '200': '#ecb7b7',
    // '300': '#e49a9a',
    // '400': '#df8484',
    // '500': '#d96e6e',
    // '600': '#d56666',
    // '700': '#cf5b5b',
    // '800': '#ca5151',
    // '900': '#c03f3f',

    '50': '#f9ecec', // medium
    '100': '#f0cfcf',
    '200': '#e6afaf',
    '300': '#db8e8e',
    '400': '#d47676',
    '500': '#cc5e5e',
    '600': '#c75656',
    '700': '#c04c4c',
    '800': '#b94242',
    '900': '#ad3131',
  },
  secondary: {
    '50': '#fff8ef',
    '100': '#feeed7',
    '200': '#fee2bd',
    '300': '#fed6a3',
    '400': '#fdce8f',
    '500': '#fdc57b',
    '600': '#fdbf73',
    '700': '#fcb868',
    '800': '#fcb05e',
    '900': '#fca34b',
  },
  dark: {
    // '50': '#e8e8eb',
    // '100': '#c6c6cd',
    // '200': '#a0a1ac',
    // '300': '#797b8a',
    // '400': '#5d5e71',
    // '500': '#404258',
    // '600': '#3a3c50',
    // '700': '#323347',
    // '800': '#2a2b3d',
    // '900': '#1c1d2d',

    '50': '#e4e6ea',
    '100': '#bbc0cb',
    '200': '#8e96a8',
    '300': '#616c85',
    '400': '#3f4d6a',
    '500': '#1d2d50',
    '600': '#1a2849',
    '700': '#152240',
    '800': '#111c37',
    '900': '#0a1127',

    // '50': '#e4e4e8',
    // '100': '#bcbcc6',
    // '200': '#9090a0',
    // '300': '#636379',
    // '400': '#41415d',
    // '500': '#202040',
    // '600': '#1c1c3a',
    // '700': '#181832',
    // '800': '#13132a',
    // '900': '#0b0b1c',
  },
  grey: {
    '50': '#e7e7e7',
    '100': '#c4c4c4',
    '200': '#9d9d9d',
    '300': '#757575',
    '400': '#585858',
    '500': '#3a3a3a',
    '600': '#343434',
    '700': '#2c2c2c',
    '800': '#252525',
    '900': '#181818',
  },
  // dark: '#312b47',
  black: '#423c57',
  light: '#ffffff',
  text: '#353535',
  success: '#3c9564',
  error: '#d84a27',
  info: '#0386d6',
  warning: '#ffbb00',
};
